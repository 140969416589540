import { createStore, applyMiddleware, combineReducers } from "redux";
import axiosMiddleware from "redux-axios-middleware";
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import axiosInstance from "../utils/AxiosHelper";
import AuthenticationSlice, { logout } from "./features/AuthenticationSlice";
import ClientSlice from "./features/ClientSlice";
import PharmacySlice from "./features/PharmacySlice";
import ImportedSlice from "./features/ImportedSlice";

const composeEnhancers = composeWithDevTools({ name: "dubivax_adm" });

const keyPrefix = "dubivax_adm";

const axiosMiddlewareConfig = {
  interceptors: {
    response: [{
      success({ getState, dispatch, getSourceAction }, res) {
        return Promise.resolve(res);
      },
      error({ getState, dispatch, getSourceAction }, error) {
        if (error.response.status === 401) {
          dispatch(logout());
        }

        return Promise.reject(error);
      },
    }],
  },
};

function ConfigureStore(initialState = {}) {
  const store = createStore(
    combineReducers({
      authentication: persistReducer({
        key: `${keyPrefix}authentication`,
        debug: true,
        storage,
      }, AuthenticationSlice),
      client: ClientSlice,
      pharmacy: PharmacySlice,
      imported: ImportedSlice,
    }),
    initialState,
    composeEnhancers(applyMiddleware(axiosMiddleware(
      axiosInstance(),
      axiosMiddlewareConfig,
    ))),
  );

  const persistor = persistStore(store);

  return { store, persistor };
}

export default ConfigureStore;
