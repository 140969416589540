import React, { memo } from "react";

import PropTypes from "prop-types";
import * as dayjs from "dayjs";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-light-svg-icons";
import Table from "react-bootstrap/Table";
import { CSVLink } from "react-csv";

const StatisticTable = (props) => {
  const {
    data,
    extended,
  } = props;

  const currentMonth = dayjs();

  const csvHeaders = extended ? [
    { label: "Abrechnungszeitraum", key: "date" },
    { label: "IK", key: "ikNumber" },
    { label: "Apothekenname", key: "client" },
    { label: "Adresse", key: "address" },
    { label: "PLZ", key: "zip" },
    { label: "Ort", key: "city" },
    { label: "Inhaber", key: "owner" },
    { label: "Anzahl Termine", key: "all" },
    { label: "Abrechenbare Termine", key: "billable" },
    { label: "Anzahl SMS", key: "smsCount" },
    { label: "Datum Onboarding", key: "createdAt" },
  ] : [
    { label: "Termine gesamt", key: "all" },
    { label: "von Apot. storniert", key: "deleted" },
    { label: "von Pat. storniert", key: "deletedPatient" },
    { label: "nicht erschienen", key: "expired" },
    { label: "an das RKI gemeldet", key: "done" },
    { label: "Abrechenbare Termine", key: "billable" },
    { label: "SMS zu 0,07 €", key: "smsCount" },
  ];

  return (
    <Table>
      <thead>
        <tr>
          <td />
          <th>
            Termine<br />gesamt
          </th>
          <th>
            von Apot.<br />storniert
          </th>
          <th>
            von Pat.<br />storniert
          </th>
          <th>
            nicht<br />erschienen
          </th>
          <th>
            an das RKI<br />gemeldet
          </th>
          <th>
            Abrechenbare<br />Termine
          </th>
          <th>
            SMS zu<br />0,07 €
          </th>
          <td />
        </tr>
      </thead>
      <tbody>
        {data.map((stat) => {
          const month = dayjs()
            .year(stat.year)
            .month(stat.month - 1);

          return (
            <tr key={`${stat.year}${stat.month}`}>
              <td className="align-middle">
                {currentMonth.format("YYMM") === month.format("YYMM")
                  ? <strong>{month.format("MMMM YYYY")}</strong>
                  : month.format("MMMM YYYY")}
              </td>
              <td className="align-middle">
                <Badge pill bg="secondary">{stat.all}</Badge>
              </td>
              <td className="align-middle">
                <Badge pill bg="danger">{stat.deleted}</Badge>
              </td>
              <td className="align-middle">
                <Badge pill bg="danger">{stat.deletedPatient}</Badge>
              </td>
              <td className="align-middle">
                <Badge pill bg="warning">{stat.expired}</Badge>
              </td>
              <td className="align-middle">
                <Badge pill bg="success">{stat.done}</Badge>
              </td>
              <td className="align-middle">
                <Badge pill bg="info">{stat.billable}</Badge>
              </td>
              <td className="align-middle">
                <Badge pill bg="info">{stat.smsCount}</Badge>
              </td>
              <td className="align-middle">
                {currentMonth.format("YYMM") > month.format("YYMM") && (
                  <Button
                    size="sm"
                    as={CSVLink}
                    headers={csvHeaders}
                    data={stat.exportData || [{ ...stat }]}
                    filename={`export_${month.format("YYYY-MM")}.csv`}
                  >
                    <FontAwesomeIcon icon={faDownload} fixedWidth />
                  </Button>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

StatisticTable.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  extended: PropTypes.bool,
};

StatisticTable.defaultProps = {
  extended: false,
};

export default memo(StatisticTable);
