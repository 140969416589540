import React, { useEffect } from "react";

import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { connect } from "react-redux";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { find } from "../../state/features/PharmacySlice";

const MappingModal = (props) => {
  const {
    term,
    onSelect,
    onHide,
    show,
    findDispatch,
    pharmacies,
    pharmaciesLoading,
  } = props;

  useEffect(() => {
    findDispatch(term);
  }, [findDispatch, term]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Manuelles Mapping</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {pharmaciesLoading && (
          <div className="text-center">
            <FontAwesomeIcon icon={faSpinner} fixedWidth spin />
          </div>
        )}
        {pharmacies.map((pharmacy) => (
          <React.Fragment key={pharmacy.searchId}>
            <div className="d-flex justify-content-between">
              <div>
                <strong>{pharmacy.name}</strong><br /><br />
                {pharmacy.street}<br />
                {pharmacy.zip} {pharmacy.city}
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <Button
                  onClick={() => {
                    onSelect(pharmacy.searchId);
                  }}
                >
                  übernehmen
                </Button>
              </div>
            </div>
            <hr />
          </React.Fragment>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Schließen
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

MappingModal.propTypes = {
  term: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool,
  findDispatch: PropTypes.func.isRequired,
  pharmacies: PropTypes.arrayOf(PropTypes.object),
  pharmaciesLoading: PropTypes.bool,
};

MappingModal.defaultProps = {
  show: false,
  pharmacies: [],
  pharmaciesLoading: false,
};

const mapStateToProps = ({ pharmacy }) => ({
  pharmacies: pharmacy.pharmacies,
  pharmaciesLoading: pharmacy.pharmaciesLoading,
});

const mapDispatch = {
  findDispatch: find,
};

export default connect(mapStateToProps, mapDispatch)(MappingModal);
