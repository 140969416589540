import React, { useEffect, useState } from "react";

import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  faCheck,
  faTimes,
  faCog,
  faSearch,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as dayjs from "dayjs";
import { NavLink } from "react-router-dom";

import { fetchAll, remove } from "../../state/features/ClientSlice";
import Panel from "../../components/Panel/Panel";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";

const ClientOverview = (props) => {
  const {
    fetchAllDispatch,
    removeDispatch,
    clients,
  } = props;

  useEffect(() => {
    fetchAllDispatch();
  }, [fetchAllDispatch]);

  const resellers = ["dubivax", "Noventi", "Gesund.de"];

  const [resellerFilter, setResellerFilter] = useState(resellers);

  return (
    <Panel>
      <h3>Kunden</h3>

      <div className="d-flex align-items-center mb-3">
        <span className="me-2 text-secondary">Filter:</span>
        <ToggleButtonGroup
          type="checkbox"
          defaultValue={resellerFilter}
          onChange={setResellerFilter}
        >
          {resellers.map((reseller) => (
            <ToggleButton key={reseller} id={`reseller-${reseller}`} value={reseller}>
              <FontAwesomeIcon icon={resellerFilter.includes(reseller) ? faCheck : faTimes} fixedWidth className="me-2" />
              {reseller}
              <span className="ms-2">
                ({clients.reduce((n, client) => (n + (client.reseller.name === reseller)), 0)})
              </span>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Reg.</th>
            <th>Aktiv</th>
            <th>Freig.</th>
            <th>Mapping</th>
            <th>Reseller</th>
            <th>Angelegt am</th>
            <th className="text-center">
              <FontAwesomeIcon icon={faCog} fixedWidth />
            </th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client) => {
            if (!resellerFilter.includes(client.reseller.name)) {
              return null;
            }

            return (
              <tr key={client.ulid}>
                <td className="align-middle">
                  {client.name}<br />
                  {client.street && client.zip && client.city && (
                    <small className="text-muted">{client.street}, {client.zip} {client.city}</small>
                  )}
                </td>
                <td className="align-middle">
                  {client.registered
                    ? <FontAwesomeIcon icon={faCheck} fixedWidth />
                    : <FontAwesomeIcon icon={faTimes} fixedWidth />}
                </td>
                <td className="align-middle">
                  {client.active
                    ? <FontAwesomeIcon icon={faCheck} fixedWidth />
                    : <FontAwesomeIcon icon={faTimes} fixedWidth />}
                </td>
                <td className="align-middle">
                  {client.approved
                    ? <FontAwesomeIcon icon={faCheck} fixedWidth />
                    : <FontAwesomeIcon icon={faTimes} fixedWidth />}
                </td>
                <td className="align-middle">
                  {client.searchId !== null
                    ? <FontAwesomeIcon icon={faCheck} fixedWidth />
                    : <FontAwesomeIcon icon={faTimes} fixedWidth />}
                </td>
                <td className="align-middle">{client.reseller.name}</td>
                <td className="align-middle">{dayjs(client.createdAt).format("L LT")}</td>
                <td className="text-center align-middle text-nowrap">
                  <Button as={NavLink} size="sm" to={`/client/${client.ulid}`} className="me-2">
                    <FontAwesomeIcon icon={faSearch} fixedWidth />
                  </Button>

                  <ConfirmDialog
                    term={client.name}
                    text="Beim löschen werden alle zugehörigen Datensätze (Arbeits- und Sperrzeiten, Termine, Terminarten sowie Benutzer) ebenfalls gelöscht. Dieser Vorgang kann nicht Rückgängig gemacht werden."
                    onSuccess={() => {
                      removeDispatch(client.ulid)
                        .then(() => {
                          fetchAllDispatch();
                        });
                    }}
                  >
                    <Button size="sm" variant="danger">
                      <FontAwesomeIcon icon={faTrash} fixedWidth />
                    </Button>
                  </ConfirmDialog>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Panel>
  );
};

ClientOverview.propTypes = {
  clients: PropTypes.oneOfType([
    PropTypes.array,
  ]),
  fetchAllDispatch: PropTypes.func.isRequired,
  removeDispatch: PropTypes.func.isRequired,
};

ClientOverview.defaultProps = {
  clients: [],
};

const mapStateToProps = ({ client }) => ({
  clients: client.clients,
});

const mapDispatch = {
  fetchAllDispatch: fetchAll,
  removeDispatch: remove,
};

export default connect(mapStateToProps, mapDispatch)(ClientOverview);
