import React from "react";

import {
  faSignOut,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";

const TopNavigation = () => (
  <Navbar bg="secondary" variant="dark" expand="md" fixed="top">
    <Container fluid="xxl">
      <Navbar.Brand as={NavLink} to="/">
        dubivax Admin
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse>
        <Nav className="ms-auto">
          <NavLink className="nav-link" to="/login">
            Logout
            <FontAwesomeIcon icon={faSignOut} fixedWidth className="ms-2" />
          </NavLink>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
);

export default TopNavigation;
