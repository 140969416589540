import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pharmacies: [],
  pharmaciesLoading: false,
};

const PharmacySlice = createSlice({
  name: "pharmacy",
  initialState,
  reducers: {
    find: (state) => ({
      ...state,
      pharmacies: [],
      pharmaciesLoading: true,
    }),
    find_SUCCESS: (state, { payload }) => ({
      ...state,
      pharmacies: payload.data.result,
      pharmaciesLoading: false,
    }),
    find_FAIL: (state) => ({
      ...state,
      ...initialState,
    }),
  },
});

export default PharmacySlice.reducer;

export const find = (term) => ({
  type: "pharmacy/find",
  payload: {
    client: "default",
    request: {
      url: `admin/pharmacy/${term}`,
    },
  },
});
