import React from "react";

import Panel from "../../components/Panel/Panel";

const Dashboard = () => (
  <Panel>
    <h3>Dashboard</h3>
  </Panel>
);

export default Dashboard;
