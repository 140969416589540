import React, { useState } from "react";

import Button from "react-bootstrap/Button";
import { faExclamationTriangle, faSpinner } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { ProgressBar } from "react-bootstrap";
import classNames from "classnames";

import Panel from "../../components/Panel/Panel";
import { exportNoventi, importGesundDe, importGesundDePurge } from "../../state/features/ImportedSlice";

const GesundDe = (props) => {
  const {
    importGesundDeDispatch,
    importGesundDePurgeDispatch,
    exportNoventiDispatch,
  } = props;

  const [content, setContent] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [importedRows, setImportedRows] = useState(0);
  const [running, setRunning] = useState(false);
  const [recipient, setRecipient] = useState(null);

  const stepSize = 5;

  const readFile = async (event) => {
    event.preventDefault();
    const reader = new FileReader();
    reader.onload = async (loadEvent) => {
      if (event.target.files[0].type !== "text/csv") {
        setContent(null);

        return;
      }

      setContent(loadEvent.target.result);
      setTotalRows(loadEvent.target.result.split(/\r\n|\r|\n/).length - 1);
      setImportedRows(0);
    };
    reader.readAsText(event.target.files[0]);
  };

  const submit = async () => {
    let position = 0;

    setRunning(true);
    setImportedRows(0);

    while (position < totalRows) {
      // eslint-disable-next-line no-await-in-loop
      await importGesundDeDispatch(
        content
          .split(/\r\n|\r|\n/)
          .slice(position + 1, position + 1 + stepSize)
          .join("\r\n"),
      );

      position += stepSize;

      if (position >= totalRows) {
        position = totalRows;
      }

      setImportedRows(position);
    }

    await importGesundDePurgeDispatch(content);

    setRunning(false);
  };

  return (
    <>
      <Panel className="mb-3">
        <h3 className="mb-3">Export von Noventi Daten</h3>

        <div className="row row-cols-lg-auto g-3">
          <div className="col-12">
            <input
              className="form-control w-auto"
              placeholder="Empfänger"
              type="email"
              onChange={({ target: { value } }) => {
                setRecipient(value !== "" ? value : null);
              }}
            />
          </div>

          <div className="col-12">
            <Button
              onClick={() => {
                exportNoventiDispatch(recipient);
              }}
              disabled={recipient === null}
            >
              Exportieren
            </Button>
          </div>
        </div>
      </Panel>

      <Panel>
        <h3 className="mb-3">Import von Gesund.de Daten</h3>

        <div>
          <strong>Hinweise:</strong><br />
          <ul>
            <li>Es muss eine CSV-Datei importiert werden (Trennzeichen ist ein Semicolon)</li>
            {/* eslint-disable-next-line max-len */}
            <li>Reihenfolge muss <i>IK, Apothekenname, Strasse, Hausnummer, PLZ, Ort, Link</i> entsprechen</li>
            <li>Die erste Zeile wird immer automatisch übersprungen</li>
            <li>Eine leere Datei löscht alle Datensätze für den Reseller <i>Gesund.de</i></li>
          </ul>
        </div>

        <div className={classNames({ "mb-3": content !== null })}>
          <input className="form-control" type="file" onChange={readFile} />
        </div>

        {content && (
          <>
            <Button
              className="mb-3"
              variant="warning"
              onClick={submit}
              disabled={running}
            >
              <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
              Importieren
              {running && (
                <FontAwesomeIcon icon={faSpinner} fixedWidth spin />
              )}
            </Button>

            <ProgressBar
              className="mb-3"
              animated={running}
              now={importedRows}
              max={totalRows}
              label={`${importedRows} / ${totalRows}`}
            />

            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Vorschau:</h5>
                <pre className="mb-0">
                  <code>
                    {content}
                  </code>
                </pre>
              </div>
            </div>
          </>
        )}
      </Panel>
    </>
  );
};

GesundDe.propTypes = {
  // loading: PropTypes.bool,
  // success: PropTypes.bool,
  // fail: PropTypes.bool,
  importGesundDeDispatch: PropTypes.func.isRequired,
  importGesundDePurgeDispatch: PropTypes.func.isRequired,
  exportNoventiDispatch: PropTypes.func.isRequired,
};

GesundDe.defaultProps = {
  // loading: false,
  // success: false,
  // fail: false,
};

const mapStateToProps = ({ imported }) => ({
  loading: imported.loading,
  success: imported.success,
  fail: imported.fail,
});

const mapDispatch = {
  importGesundDeDispatch: importGesundDe,
  importGesundDePurgeDispatch: importGesundDePurge,
  exportNoventiDispatch: exportNoventi,
};

export default connect(mapStateToProps, mapDispatch)(GesundDe);
