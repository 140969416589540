import React from "react";

import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import * as dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ConfigureStore from "./state/ConfigureStore";

if (typeof process.env.REACT_APP_SENTRY_DSN !== "undefined") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
  });
}

require("dayjs/locale/de");

dayjs.extend(localizedFormat);
dayjs.locale("de");

const { store, persistor } = ConfigureStore();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);

reportWebVitals();
