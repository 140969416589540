import React, { useEffect } from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";

import { fetchStatistics } from "../../state/features/ClientSlice";
import Panel from "../../components/Panel/Panel";
import StatisticTable from "../../components/StatisticTable/StatisticTable";

const Statistics = (props) => {
  const {
    statistics,
    fetchStatisticsDispatch,
  } = props;

  useEffect(() => {
    fetchStatisticsDispatch();
  }, [fetchStatisticsDispatch]);

  const month = [];

  for (const [i, statisticMonth] of Object.entries(statistics)) {
    const [y, m] = i.split(".");

    for (const [, client] of Object.entries(statisticMonth)) {
      const key = `${y}${m}${client.reseller}`;

      const data = {
        ...client,
        date: `${m}.${y}`,
      };

      if (typeof month[key] === "undefined") {
        month[key] = {
          ...client,
          month: m,
          year: y,
          exportData: [
            data,
          ],
        };
      } else {
        month[key].all = parseInt(month[key].all, 10) + parseInt(client.all, 10);
        month[key].deleted = parseInt(month[key].deleted, 10) + parseInt(client.deleted, 10);
        month[key].deletedPatient = parseInt(month[key].deletedPatient, 10)
          + parseInt(client.deletedPatient, 10);
        month[key].expired = parseInt(month[key].expired, 10) + parseInt(client.expired, 10);
        month[key].done = parseInt(month[key].done, 10) + parseInt(client.done, 10);
        month[key].billable = parseInt(month[key].billable, 10) + parseInt(client.billable, 10);
        month[key].smsCount = parseInt(month[key].smsCount, 10) + parseInt(client.smsCount, 10);
        month[key].exportData.push(data);
      }
    }
  }

  const resellers = {};

  Object.keys(month).forEach((key) => {
    if (typeof resellers[month[key].reseller] === "undefined") {
      resellers[month[key].reseller] = [];
    }

    resellers[month[key].reseller].push(month[key]);
  });

  return (
    <>
      <Panel className="mb-3">
        <h3>Statistiken</h3>
      </Panel>

      {Object.entries(resellers).map(([reseller, stats]) => (
        <Panel title={reseller} className="mb-3" key={reseller}>
          <StatisticTable
            extended
            data={stats}
          />
        </Panel>
      ))}
    </>
  );
};

Statistics.propTypes = {
  statistics: PropTypes.oneOfType([
    PropTypes.array,
  ]),
  fetchStatisticsDispatch: PropTypes.func.isRequired,
};

Statistics.defaultProps = {
  statistics: [],
};

const mapStateToProps = ({ client }) => ({
  statistics: client.statistics,
});

const mapDispatch = {
  fetchStatisticsDispatch: fetchStatistics,
};

export default connect(mapStateToProps, mapDispatch)(Statistics);
