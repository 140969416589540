import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import { Outlet, useNavigate } from "react-router-dom";

import Navigation from "../../components/Navigation/Navigation";
import TopNavigation from "../../components/TopNavigation/TopNavigation";
import { login, logout } from "../../state/features/AuthenticationSlice";

const Login = (props) => {
  const {
    loginDispatch,
    logoutDispatch,
  } = props;

  useEffect(() => {
    logoutDispatch();
  }, [logoutDispatch]);

  const navigate = useNavigate();
  const [loginFailed, setLoginFailed] = useState(false);

  const validationSchema = yup.object().shape({
    username: yup.string()
      .required()
      .max(255),
    password: yup.string()
      .required()
      .max(255),
    totp: yup.string()
      .required()
      .length(6),
  });

  return (
    <>
      <Container fluid="xxl" className="pt-6" style={{ filter: "blur(5px)" }}>
        <TopNavigation />
        <Row>
          <Col md={3}>
            <Navigation />
          </Col>
          <Col md={9}>
            <Outlet />
          </Col>
        </Row>
      </Container>

      <Modal
        show
        centered
        backdrop="static"
      >
        <Formik
          initialValues={{ username: "", password: "", totp: "" }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);

            loginDispatch(values)
              .then((action) => {
                setSubmitting(false);

                if (action.type.endsWith("SUCCESS")) {
                  setLoginFailed(false);
                  navigate("/", { replace: true });
                } else {
                  setLoginFailed(true);
                }
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Header>
                <Modal.Title>Login required</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form.Group className="mb-3" controlId="username">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    name="username"
                    placeholder="Ihre E-Mail Adresse"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.username}
                    isInvalid={!!(errors.username && touched.username)}
                    autoFocus
                  />

                  {errors.username && touched.username && (
                    <Form.Control.Feedback type="invalid">
                      {errors.username}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group className="mb-3" controlId="password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Ihr Passwort"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    isInvalid={!!(errors.password && touched.password)}
                  />

                  {errors.password && touched.password && (
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group className="mb-3" controlId="totp">
                  <Form.Label>2FA</Form.Label>
                  <Form.Control
                    type="text"
                    name="totp"
                    placeholder="2FA Token"
                    maxLength={6}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.totp}
                    isInvalid={!!(errors.totp && touched.totp)}
                  />

                  {errors.totp && touched.totp && (
                    <Form.Control.Feedback type="invalid">
                      {errors.totp}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                {loginFailed && (
                  <Alert variant="warning">
                    Wrong credentials
                  </Alert>
                )}
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Login
                </Button>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

Login.propTypes = {
  loginDispatch: PropTypes.func.isRequired,
  logoutDispatch: PropTypes.func.isRequired,
};

const mapDispatch = {
  loginDispatch: login,
  logoutDispatch: logout,
};

export default connect(null, mapDispatch)(Login);
