import React, { memo } from "react";

import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";

const Panel = (props) => {
  const {
    className,
    children,
    title,
  } = props;

  return (
    <Card className={className}>
      {title && <Card.Header>{title}</Card.Header>}
      <Card.Body>
        {children}
      </Card.Body>
    </Card>
  );
};

Panel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  title: PropTypes.string,
};

Panel.defaultProps = {
  className: null,
  children: null,
  title: null,
};

export default memo(Panel);
