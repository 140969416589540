import React, { useState } from "react";

import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

const ConfirmDialog = (props) => {
  const {
    children,
    onSuccess,
    text,
    term,
  } = props;

  const [showModal, setShowModal] = useState(false);
  const [input, setInput] = useState(null);

  const hideModal = () => {
    setInput(null);
    setShowModal(false);
  };

  return (
    <>
      <span
        onClick={() => setShowModal(true)}
        onKeyPress={() => setShowModal(true)}
        role="button"
        tabIndex="0"
      >
        {children}
      </span>

      <Modal show={showModal} onHide={hideModal}>
        <Modal.Body>
          <p>{text}</p>

          <p>
            Bitte geben Sie in das nachfolgende Feld <strong>{term}</strong> ein, um
            den Vorgang zu bestätigen.
          </p>

          <Form.Control
            type="text"
            placeholder={term}
            onChange={({ target }) => {
              setInput(target.value);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={hideModal}
          >
            Abbrechen
          </Button>
          <Button
            variant="primary"
            disabled={input !== term}
            onClick={() => {
              onSuccess();
              hideModal();
            }}
          >
            Bestätigen
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ConfirmDialog.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  text: PropTypes.string.isRequired,
  term: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default ConfirmDialog;
