import React from "react";

import Nav from "react-bootstrap/Nav";
import Card from "react-bootstrap/Card";
import { NavLink } from "react-router-dom";

const Navigation = () => (
  <Card>
    <Card.Body>
      <h3>Navigation</h3>
      <Nav defaultActiveKey="/" className="flex-column">
        <NavLink className="nav-link" to="/">Dashboard</NavLink>
        <NavLink className="nav-link" to="/statistics">Statistiken</NavLink>
        <NavLink className="nav-link" to="/clients">Kunden</NavLink>
        <NavLink className="nav-link" to="/import/gesundde">Export/Import</NavLink>
      </Nav>
    </Card.Body>
  </Card>
);

export default Navigation;
