import axios from "axios";

const axiosInstance = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": "de-DE",
    },
  };

  const instance = axios.create(defaultOptions);

  instance.interceptors.request.use((config) => {
    const authToken = localStorage.getItem("authToken");
    const newConfig = config;

    if (authToken !== null) {
      newConfig.headers.Authorization = `Bearer ${authToken}`;
    }

    return newConfig;
  });

  return instance;
};

export default axiosInstance;
