import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loggedIn: false,
  username: null,
  firstname: null,
  lastname: null,
  authToken: null,
};

const AuthenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("authToken");

      return {
        ...state,
        ...initialState,
      };
    },
    login: (state) => {
      localStorage.removeItem("authToken");

      return {
        ...state,
        loggedIn: false,
        username: null,
        firstname: null,
        lastname: null,
        authToken: null,
      };
    },
    login_SUCCESS: (state, { payload }) => {
      localStorage.setItem("authToken", payload.data.authToken);

      return {
        ...state,
        loggedIn: true,
        username: payload.data.username,
        firstname: payload.data.firstname,
        lastname: payload.data.lastname,
        authToken: payload.data.authToken,
      };
    },
    login_FAIL: (state) => {
      localStorage.removeItem("authToken");

      return {
        ...state,
        loggedIn: false,
        username: null,
        firstname: null,
        lastname: null,
        authToken: null,
      };
    },
  },
});

export const { logout } = AuthenticationSlice.actions;

export default AuthenticationSlice.reducer;

export const login = (credentials) => ({
  type: "authentication/login",
  payload: {
    client: "default",
    request: {
      method: "post",
      data: credentials,
      url: "admin/login",
    },
  },
});
