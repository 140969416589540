import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clients: [],
  client: null,
  clientStatistics: [],
  statistics: [],
  toggleLoading: false,
  changeSearchIdLoading: false,
};

const ClientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    fetchAll: (state) => ({
      ...state,
      clients: [],
    }),
    fetchAll_SUCCESS: (state, { payload }) => ({
      ...state,
      clients: payload.data.clients,
    }),
    fetchAll_FAIL: (state) => ({
      ...state,
      clients: [],
    }),
    fetch: (state) => ({
      ...state,
      client: null,
      clientStatistics: [],
    }),
    fetch_SUCCESS: (state, { payload }) => ({
      ...state,
      client: payload.data.client,
      clientStatistics: payload.data.statistics,
    }),
    fetch_FAIL: (state) => ({
      ...state,
      client: null,
      clientStatistics: [],
    }),
    fetchStatistics: (state) => ({
      ...state,
      statistics: [],
    }),
    fetchStatistics_SUCCESS: (state, { payload }) => ({
      ...state,
      statistics: payload.data.statistics,
    }),
    fetchStatistics_FAIL: (state) => ({
      ...state,
      statistics: [],
    }),
    toggle: (state) => ({
      ...state,
      toggleLoading: true,
    }),
    toggle_SUCCESS: (state, { payload }) => ({
      ...state,
      toggleLoading: false,
    }),
    toggle_FAIL: (state) => ({
      ...state,
      toggleLoading: false,
    }),
    changeSearchId: (state) => ({
      ...state,
      changeSearchIdLoading: true,
    }),
    changeSearchId_SUCCESS: (state, { payload }) => ({
      ...state,
      changeSearchIdLoading: false,
    }),
    changeSearchId_FAIL: (state) => ({
      ...state,
      changeSearchIdLoading: false,
    }),
  },
});

export default ClientSlice.reducer;

export const fetchAll = () => ({
  type: "client/fetchAll",
  payload: {
    client: "default",
    request: {
      url: "admin/client",
    },
  },
});

export const fetch = (ulid) => ({
  type: "client/fetch",
  payload: {
    client: "default",
    request: {
      url: `admin/client/details/${ulid}`,
    },
  },
});

export const fetchStatistics = () => ({
  type: "client/fetchStatistics",
  payload: {
    client: "default",
    request: {
      url: "admin/client/statistics",
    },
  },
});

export const toggle = (ulid, value) => ({
  type: "client/toggle",
  payload: {
    client: "default",
    request: {
      method: "post",
      data: {
        approved: value,
      },
      url: `admin/client/toggle/${ulid}`,
    },
  },
});

export const changeSearchId = (ulid, value) => ({
  type: "client/changeSearchId",
  payload: {
    client: "default",
    request: {
      method: "post",
      data: {
        searchId: value,
      },
      url: `admin/client/search-id/${ulid}`,
    },
  },
});

export const remove = (ulid) => ({
  type: "client/remove",
  payload: {
    client: "default",
    request: {
      method: "delete",
      url: `admin/client/remove/${ulid}`,
    },
  },
});
