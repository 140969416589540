import React, { useEffect, useState } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  faSpinner,
  faLink,
  faUnlink,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  changeSearchId,
  fetch,
  toggle,
} from "../../state/features/ClientSlice";
import Panel from "../../components/Panel/Panel";
import MappingModal from "../../components/MappingModal/MappingModal";
import StatisticTable from "../../components/StatisticTable/StatisticTable";

const ClientDetails = (props) => {
  const {
    fetchDispatch,
    toggleDispatch,
    client,
    clientStatistics,
    toggleLoading,
    changeSearchIdDispatch,
    changeSearchIdLoading,
  } = props;

  const {
    ulid,
  } = useParams();

  const [showMappingModal, setShowMappingModal] = useState(false);
  const [searchId, setSearchId] = useState(null);

  useEffect(() => {
    fetchDispatch(ulid);
  }, [fetchDispatch, ulid]);

  if (client === null) {
    return null;
  }

  const sortedStatistics = [...clientStatistics].sort((a, b) => {
    if (parseInt(a.year, 10) === parseInt(b.year, 10)) {
      return parseInt(b.month, 10) - parseInt(a.month, 10);
    }

    return parseInt(b.year, 10) - parseInt(a.year, 10);
  });

  return (
    <>
      <Panel className="mb-3">
        <h3>Kunde: {client.name}</h3>
      </Panel>

      <Panel title="Allgemeines" className="mb-3">
        <Row className="mb-3">
          <Col md={4}>
            Auf Impf-Einrichtung zugreifen
          </Col>
          <Col md={8}>
            <a href="#test" className="btn btn-primary btn-small">Impf-Einrichtung aufrufen</a>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={4}>
            Kundengruppe
          </Col>
          <Col md={8}>
            {client.reseller.name}
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            ULID
          </Col>
          <Col md={8}>
            {client.ulid}
          </Col>
        </Row>
      </Panel>

      <Panel title="Status der Impf-Einrichtung" className="mb-3">
        <Row className="mb-3">
          <Col md={4}>
            Freigeschaltet
          </Col>
          <Col md={8} className="d-flex align-items-center">
            <Form.Check
              type="switch"
              id="approved"
              defaultChecked={client.approved}
              onChange={(event) => {
                toggleDispatch(client.ulid, event.target.checked);
              }}
            />
            {toggleLoading && (
              <FontAwesomeIcon icon={faSpinner} fixedWidth spin />
            )}
          </Col>
        </Row>

        <Form.Group as={Row} className="mb-3" controlId="searchId">
          <Form.Label column md="4">
            Mapping-ID
          </Form.Label>
          <Col md="4">
            <Form.Control readOnly value={client.searchId || searchId} />
          </Col>
          <Col md="4">
            {(client.searchId) && (
              <Button
                onClick={() => {
                  setSearchId(null);
                  changeSearchIdDispatch(client.ulid, null)
                    .then(() => {
                      fetchDispatch(client.ulid);
                    });
                }}
                className="me-2"
                variant="warning"
              >
                <FontAwesomeIcon icon={faUnlink} className="me-2" />
                lösen
              </Button>
            )}
            <Button
              onClick={() => {
                setShowMappingModal(true);
              }}
            >
              <FontAwesomeIcon icon={faLink} className="me-2" />
              manuelles Mapping
            </Button>
            {changeSearchIdLoading && (
              <FontAwesomeIcon icon={faSpinner} fixedWidth spin />
            )}
          </Col>
        </Form.Group>
      </Panel>

      <Panel title="Stammdaten" className="mb-3">
        <Form.Group as={Row} className="mb-3" controlId="name">
          <Form.Label column md="4">
            Bezeichung
          </Form.Label>
          <Col md="8">
            <Form.Control readOnly value={client.name} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="street">
          <Form.Label column md="4">
            Straße
          </Form.Label>
          <Col md="8">
            <Form.Control readOnly value={client.street} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="houseNumber">
          <Form.Label column md="4">
            Hausnummer
          </Form.Label>
          <Col md="8">
            <Form.Control readOnly value={client.houseNumber} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="zip">
          <Form.Label column md="4">
            PLZ
          </Form.Label>
          <Col md="8">
            <Form.Control readOnly value={client.zip} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="city">
          <Form.Label column md="4">
            Ort
          </Form.Label>
          <Col md="8">
            <Form.Control readOnly value={client.city} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="ikNumber">
          <Form.Label column md="4">
            IK-Nummer
          </Form.Label>
          <Col md="8">
            <Form.Control readOnly value={client.ikNumber} />
          </Col>
        </Form.Group>
      </Panel>

      <Panel title="Kontaktdaten" className="mb-3">
        <Form.Group as={Row} className="mb-3" controlId="firstname">
          <Form.Label column md="4">
            Vorname
          </Form.Label>
          <Col md="8">
            <Form.Control readOnly value={client.firstname} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="lastname">
          <Form.Label column md="4">
            Nachname
          </Form.Label>
          <Col md="8">
            <Form.Control readOnly value={client.lastname} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="phone">
          <Form.Label column md="4">
            Telefonnummer
          </Form.Label>
          <Col md="8">
            <Form.Control readOnly value={client.phone} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="email">
          <Form.Label column md="4">
            E-Mail-Adresse
          </Form.Label>
          <Col md="8">
            <Form.Control readOnly value={client.email} />
          </Col>
        </Form.Group>
      </Panel>

      <Panel title="Termin-Statistiken 2022" className="mb-3">
        <StatisticTable
          data={sortedStatistics}
        />
      </Panel>

      {showMappingModal && (
        <MappingModal
          show={showMappingModal}
          term={client.zip}
          onSelect={(newSearchId) => {
            changeSearchIdDispatch(client.ulid, newSearchId);
            setSearchId(newSearchId);
            setShowMappingModal(false);
          }}
          onHide={() => {
            setShowMappingModal(false);
          }}
        />
      )}
    </>
  );
};

ClientDetails.propTypes = {
  client: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  clientStatistics: PropTypes.oneOfType([
    PropTypes.array,
  ]),
  toggleLoading: PropTypes.bool,
  fetchDispatch: PropTypes.func.isRequired,
  toggleDispatch: PropTypes.func.isRequired,
  changeSearchIdDispatch: PropTypes.func.isRequired,
  changeSearchIdLoading: PropTypes.bool,
};

ClientDetails.defaultProps = {
  client: null,
  clientStatistics: [],
  toggleLoading: false,
  changeSearchIdLoading: false,
};

const mapStateToProps = ({ client }) => ({
  client: client.client,
  clientStatistics: client.clientStatistics,
  toggleLoading: client.toggleLoading,
  changeSearchIdLoading: client.changeSearchIdLoading,
});

const mapDispatch = {
  fetchDispatch: fetch,
  toggleDispatch: toggle,
  changeSearchIdDispatch: changeSearchId,
};

export default connect(mapStateToProps, mapDispatch)(ClientDetails);
