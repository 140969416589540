import React from "react";

import Container from "react-bootstrap/Container";
import { Outlet } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Navigation from "../Navigation/Navigation";
import TopNavigation from "../TopNavigation/TopNavigation";

const Layout = () => (
  <Container fluid="xxl" className="pt-6">
    <TopNavigation />
    <Row>
      <Col md={3}>
        <Navigation />
      </Col>
      <Col md={9}>
        <Outlet />
      </Col>
    </Row>
  </Container>
);

export default Layout;
