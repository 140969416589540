import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  success: false,
  fail: false,
};

const ImportedSlice = createSlice({
  name: "imported",
  initialState,
  reducers: {
    importGesundDe: (state) => ({
      ...state,
      loading: true,
      success: false,
      fail: false,
    }),
    importGesundDe_SUCCESS: (state) => ({
      ...state,
      loading: false,
      success: true,
      fail: false,
    }),
    importGesundDe_FAIL: (state) => ({
      ...state,
      loading: false,
      success: false,
      fail: true,
    }),
  },
});

export default ImportedSlice.reducer;

export const importGesundDe = (csvString) => ({
  type: "imported/importGesundDe",
  payload: {
    client: "default",
    request: {
      method: "post",
      data: {
        csvString,
      },
      url: "admin/import/gesundde",
    },
  },
});

export const importGesundDePurge = (csvString) => ({
  type: "imported/importGesundDePurge",
  payload: {
    client: "default",
    request: {
      method: "post",
      data: {
        csvString,
      },
      url: "admin/import/gesundde/purge",
    },
  },
});

export const exportNoventi = (recipient) => ({
  type: "imported/exportNoventi",
  payload: {
    client: "default",
    request: {
      method: "post",
      data: {
        recipient,
      },
      url: "admin/export/noventi",
    },
  },
});
