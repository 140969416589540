import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const RequireAuth = ({ children, loggedIn }) => {
  const location = useLocation();

  if (!loggedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

RequireAuth.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  loggedIn: PropTypes.bool.isRequired,
};

RequireAuth.defaultProps = {
  children: null,
};

const mapStateToProps = ({ authentication }) => ({
  loggedIn: authentication.loggedIn,
});

export default connect(mapStateToProps)(RequireAuth);
