import React from "react";

import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";

import Layout from "./components/Layout/Layout";
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import RequireAuth from "./utils/RequireAuth";
import ClientOverview from "./pages/Client/ClientOverview";
import ClientDetails from "./pages/Client/ClientDetails";
import Statistics from "./pages/Statistics/Statistics";
import GesundDe from "./pages/Import/GesundDe";

import "./App.scss";

const App = () => (
  <div className="App">
    <BrowserRouter>
      <Routes>
        <Route element={<RequireAuth><Outlet /></RequireAuth>}>
          <Route element={<Layout />}>
            <Route index element={<Dashboard />} />
            <Route exact path="statistics/" element={<Statistics />} />
            <Route exact path="clients/" element={<ClientOverview />} />
            <Route exact path="client/:ulid" element={<ClientDetails />} />
            <Route exact path="import/gesundde" element={<GesundDe />} />
          </Route>
        </Route>
        <Route path="login/" element={<Login />} />
      </Routes>
    </BrowserRouter>
  </div>
);

export default App;
